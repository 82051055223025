<template>
  <v-dialog v-model="show" transition="dialog-bottom-transition" max-width="800px">
    <template v-slot:default="dialog">
      <v-card class="contract-decline-dialog">
        <v-toolbar dense flat>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.value = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-title>
          <h1 v-html="$t('contract.reject.reasonHtml')" class="mx-auto text-center"></h1>
        </v-card-title>

        <v-row class="pa-10 pt-0 contract-decline-dialog__buttons" align="stretch" justify="center" no-gutters>
          <v-col
            v-for="(reason, index) in reasons"
            :key="index"
            class="col-10 col-sm-4 col-md-2 contract-decline-dialog__buttons--button ma-2 text-center"
            :class="{
              'contract-decline-dialog__buttons--button-active':
                selectedReason !== null && selectedReason.value === reason.value,
            }"
            @click="selectReason(reason)"
          >
            <v-card class="pa-3" height="100%">
              {{ reason.title }}
            </v-card>
          </v-col>
        </v-row>

        <v-expand-transition>
          <v-row v-show="isSelectedReason" class="pa-10 pt-0" no-gutters>
            <v-col cols="12">
              <v-textarea
                v-model="reasonMessage"
                :label="reasonLabel"
                color="primary"
                name="reason"
                outlined
                autofocus
              ></v-textarea>
            </v-col>

            <v-col cols="12" class="text-center">
              <v-btn :loading="isSendingReason" color="primary" min-width="10rem" x-large @click="rejectContract()">
                {{ $t('general.send') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import i18n from '@/app/plugins/i18n'
import { ContractService } from '@/services/ContractService'
import { mapGetters } from 'vuex'

export default {
  name: 'ContractDeclineDialog',
  data() {
    return {
      selectedReason: null,
      sendingReason: false,
      reasonMessage: '',
    }
  },
  props: {
    value: Boolean,
    contractId: {
      type: [Number, String],
      required: true,
    },
    signIdentity: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      myIdentity: 'myIdentity',
    }),
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    reasons() {
      return [
        {
          title: i18n.t('contract.reject.reason.dontUnderstand'),
          value: 'dontUnderstand',
        },
        {
          title: i18n.t('contract.reject.reason.notForMe'),
          value: 'notForMe',
        },
        {
          title: i18n.t('contract.reject.reason.changeMind'),
          value: 'changeMind',
        },
        {
          title: i18n.t('contract.reject.reason.otherReason'),
          value: 'otherReason',
        },
      ]
    },
    reasonLabel() {
      return this.selectedReason !== null && this.selectedReason.value === 'otherReason'
        ? this.$t('contract.reject.typeOther')
        : this.$t('contract.reject.detail')
    },
    isSelectedReason() {
      return this.selectedReason !== null
    },
    isSendingReason() {
      return this.sendingReason
    },
  },
  methods: {
    selectReason(reason) {
      this.selectedReason =
        this.selectedReason !== null ? (this.selectedReason.value !== reason.value ? reason : null) : reason
    },
    rejectContract() {
      this.sendingReason = true

      // TODO - contract decline should be in vuex module contract
      ContractService.rejectAuthorized(this.contractId, {
        reason: `${this.selectedReason.title}. ${this.$t('contract.reject.note')}: ${this.reasonMessage}`,
      }).then(
        () => {
          this.$router.push({
            name: 'createRejected',
            params: { workspace_id: this.myIdentity?.workspace_id || this.signIdentity.workspace_id },
          })
          this.$notification.success(this.$t('contract.was_rejected'))
          this.sendingReason = false
        },
        () => {
          this.$notification.error(this.$t('contract.reject.failed'))
          this.sendingReason = false
        },
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.contract-decline-dialog {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-color: transparent;
    border-style: solid;
  }

  &::after {
    border-width: 3.35em;
    border-left-color: #6decaf;
    border-top-color: #6decaf;
  }

  &__buttons {
    &--button {
      overflow: hidden;
      word-wrap: break-word;
      border: 1px solid #000000;
      border-radius: 6px;
      cursor: pointer;

      &-active {
        .v-card {
          background-color: #e0e0e0;
          transition: 0.5s;
        }
      }
    }
  }
}
</style>
